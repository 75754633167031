import React from "react";
import styled from "@emotion/styled";

const Root = styled.div`

a {
  ${({ theme }) => theme.mixins.styledLink};
}

  .content-section {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;

    @media screen and (min-width: 479px) {
      margin-top: 5rem;
      margin-bottom: 5rem;
    }
    @media screen and (min-width: 767px) {
      margin-top: 7.5rem;
      margin-bottom: 7.5rem;
    }
    @media screen and (min-width: 991px) {
      margin-top: 10rem;
      margin-bottom: 10rem;
    }
  }

  h2,
  h3 {
    font-family: Lora;
    font-weight: 500;
    font-size: 1.375rem;
    margin-left: 1rem;
    margin-right: 1rem;
    @media screen and (min-width: 479px) {
      font-size: 1.5rem;
      margin-left: 2rem;
      margin-right: 2rem;
    }
    @media screen and (min-width: 767px) {
      font-size: 1.75rem;
      margin-left: auto;
      margin-right: auto;
      width: 42.5rem;
    }
    @media screen and (min-width: 991px) {
      font-size: 2rem;
      margin-left: auto;
      margin-right: auto;
      width: 46.875rem;
    }
  }

  .content-section-text {
    margin-top: 1.25rem;
    margin-bottom: 3.75rem;
    font-size: 1rem;
    line-height: 160%;

    p,
    ul {
      margin-bottom: 1em;
      margin-left: 1rem;
      margin-right: 1rem;

      @media screen and (min-width: 479px) {
        margin-left: 2rem;
        margin-right: 2rem;
      }
      @media screen and (min-width: 767px) {
        margin-left: auto;
        margin-right: auto;
        width: 42.5rem;
      }
      @media screen and (min-width: 991px) {
        margin-left: auto;
        margin-right: auto;
        width: 46.875rem;
      }
    }
    @media screen and (min-width: 479px) {
      margin-top: 1.5rem;
      font-size: 1.125rem;
    }
    @media screen and (min-width: 767px) {
      margin-top: 2.5rem;
      font-size: 1.1875rem;
    }
    @media screen and (min-width: 991px) {
      font-size: 1.3125rem;
    }

    ul {
      margin-top: 1.25rem;
      margin-bottom: 1.25rem;
      padding-left: 2.5rem;
      list-style-type: disc;
    }

    li {
      list-style-type: disc;
      margin-bottom: 0.625rem;
    }
  }

  .gatsby-resp-image-figure {
    max-width: 100rem;

    margin-top: 3.75rem;
    margin-bottom: 3.75rem;

    margin-left: 2rem;
    margin-right: 2rem;

    @media screen and (min-width: 479px) {
      margin-left: 2.5rem;
      margin-right: 2.5rem;
      margin-top: 5rem;
      margin-bottom: 5rem;
    }

    @media screen and (min-width: 767px) {
      margin-top: 7.5rem;
      margin-bottom: 7.5rem;
    }

    @media screen and (min-width: 991px) {
      margin-left: auto;
      margin-right: auto;
      margin-top: 10rem;
      margin-bottom: 10rem;
      padding-left: 5rem;
      padding-right: 5rem;
    }
  }

  figcaption {
    line-height: 150%;
    color: var(--text-secondary);
    text-align: center;
    font-size: 0.75rem;
    margin-top: 1.5rem;

    @media screen and (min-width: 767px) {
      font-size: 0.875rem;
    }
  }
`;

const Content = ({ children }) => {
  return <Root>{children}</Root>;
};

export default Content;
